<template>
<div class="home">
  <div class="header">
    <p>欢迎使用智能洗鞋柜</p>
    <p>{{date}}</p>
  </div>
  <div class="nav-title">
    <div class="arrow" @click="clickReturn">
      <img src="../../assets/img/arrow-left.png" alt="">
      退出
    </div>
    <p>请选择你要洗的鞋子类型</p>
    <div class="arrow">{{ countdown }}s</div>
  </div>
  <div class="content">
    <div class="tree-select">
      <div class="tree-select-left">
        <div class="item" :class="{actice:currentIndex == index}"
             v-for="(item,index) in treeSelectLeftList"
             :key="index"
             @click="clickSelectLeftTap(index,item.id)">
          <div class="tips" :class="{'tips-actice':currentIndex == index}"></div>
          <p class="text" :class="{'text-active':currentIndex == index}">{{item.typeName}}</p>
        </div>
      </div>

      <div class="tree-select-right">
        <div class="item"
             v-for="(item,index) in treeSelectRightList"
             :key="index"
             @click="clickShopTap(index)">
          <img :src="item.goodsUrl" alt="">
          <p class="title">{{item.goodsName}}</p>
          <p class="price">￥{{item.goodsPrice}}</p>
<!--          <p class="vip-price">会员折扣价:</p>-->
          <div v-if="item.num > 0" class="post-left-top">{{item.num}}</div>
          <div v-if="item.num > 0" class="post-right-top" @click.stop="delShoseTap(index)">
            <van-icon name="minus" color="#333333" size="20" />
          </div>
        </div>
      </div>
    </div>
    <div class="submit-bar">
      <div class="row">
        <p>数量：<span class="total">{{ num }}</span></p>
        <p>总计：￥<span class="total">{{ price }}</span>元</p>
<!--        <img src="../../assets/img/3a9e891079bc1119c3319267a853d2c.png" alt="">-->
      </div>
      <div class="btn" @click="clickOrderNumber">我选好了</div>
    </div>
  </div>


  <!--  二维码弹窗  -->
  <van-popup v-model="popupshow" :style="{ width:'40%' }" >
    <div class="popup">
      <p>请使用微信扫码支付订单</p>
      <p>请在两分钟内完成订单支付</p>
      <img class="qrcode" :src="orderNumber" alt="">
      <div class="btn" @click="clickClosePopup">取消</div>
    </div>
  </van-popup>

  <!-- 加载动画 -->
  <van-overlay :show="show" @click="show = false">
    <div class="wrapper" @click.stop>
      <img src="../../assets/img/bigproject_res_main_loading4.gif" alt="">
    </div>
  </van-overlay>
</div>
</template>

<script>
import {parseTime} from '@/utils/yiqi'
import {getShoseId, getShoseType, orderNumber,createGoodsOrder} from "../../api/shop";
import {clickShoesTypeMusic} from "../../utils/music";
export default {
  name: "StoreShoes",
  data(){
    return{
      date:'',
      timer:"",
      result: [],
      num: 0,
      price: 0,
      show: false,  //加载动画
      popupshow: false,
      orderNumber:'', //订单号
      countdown: 120,
      deviceId:"",
      currentIndex:0,
      treeSelectLeftList:[],
      treeSelectRightList:[],
    }
  },
  created() {
    if (!this.$route.query.deviceId){
      this.$toast.fail('柜子编号为空')
      return;
    }
    this.deviceId = this.$route.query.deviceId;
    this.getList()
    this.getDate()
    this.timeFn()
  },
  methods:{
    // 获取左侧列表
    getList(){
      this.show = true
      this.clickShoesTypeMusic()
      getShoseType(
        {
          pageNum: 1,
          pageSize: 99999,
          storageNumber:this.deviceId
        }
      ).then(res => {
        this.treeSelectLeftList = res.rows
        let typeId = res.rows[0].goodsType
        this.getShose(typeId)
        this.show = false
        this.num = this.result.length
      })
    },
    // 获取右侧商品
    getShose(typeId){
      getShoseId({pageNum: 1,pageSize: 99999,typeId:typeId}).then(res => {
        let carList = this.result
        res.rows.forEach(item => item.num = 0)
        if(this.result.length > 0){
          if(res.rows.length>carList.length){
            res.rows.forEach(item => {
              this.result.forEach(arr => {
                if(item.id == arr.id) item.num = arr.num
              })
            })
          }else {
            this.result.forEach(item => {
              res.rows.forEach(arr => {
                if(item.id == arr.id) arr.num = item.num
              })
            })
          }
        }
        this.treeSelectRightList = res.rows
      })
    },
    getShoseNum(list,carList){

      return list
    },
    // 选择左侧商品分类
    clickSelectLeftTap(index,id){
      this.currentIndex = index
      let typeId = this.treeSelectLeftList[index].goodsType
      this.getShose(typeId)
    },
    // 选择右侧商品
    clickShopTap(index){
      this.treeSelectRightList[index].num ++
      this.price = 0
      this.num = 0
      this.result.push(this.treeSelectRightList[index])
      const result = Object.values(this.result.reduce((acc, current) => {
        acc[current.id] = current;
        return acc;
      }, {}));
      this.result = result
      this.result.forEach(item => {
        let num = item.num
        let price = item.goodsPrice
        this.num += num
        this.price += num * price;
        this.price = Number(this.price.toFixed(2))
      })
    },
    // 选择右侧商品
    delShoseTap(index){
      this.treeSelectRightList[index].num --
      this.price = 0
      this.num = 0
      console.log(this.treeSelectRightList[index].num )
      this.result.push(this.treeSelectRightList[index])
      const result = Object.values(this.result.reduce((acc, current) => {
        acc[current.id] = current;
        return acc;
      }, {}));
      this.result = result
      this.result.forEach(item => {
        let num = item.num
        let price = item.goodsPrice
        this.num += num
        this.price += num * price;
        this.price = Number(this.price.toFixed(2))
      })
      if(this.treeSelectRightList[index].num == 0){
        this.result = this.result.filter(item => item.num != 0)
        return
      }
    },
    // 创建商品顶顶那
    clickOrderNumber(){
      console.log(this.result)
      this.clickMusic()
      if(!this.result.length){
        this.$toast.fail('请选择商品')
        return
      }
      this.result.forEach(item => {
        item.sum = item.num
      })
      let obj = {
        goodsList:this.result,
        storageNumber:this.$route.query.deviceId
      }
      console.log(obj)
      createGoodsOrder(obj).then(res => {
        console.log(res)
        this.orderNumber = res.data
        this.popupshow = true
      });


      // let deviceCode = this.$route.query.deviceId
      // let data = {
      //   deviceCode,
      //   goodsIds:goodsId
      // }
      // let order = encodeURIComponent(JSON.stringify(data))
      // if(this.result.length > 0){
      //   // this.$router.push({name:'OrderNumber',query:{order}})
      //   this.$router.push({name:'QrcodeStream',query:{order}})
      // }else {
      //   this.$toast.fail('请选择商品')
      //   return
      // }
    },
    clickClosePopup() {
      this.popupshow = false
    },
    // 获取当前时间
    getDate(){
        this.timer = setInterval(() => {
          this.date = parseTime(new Date())
        },1)
    },
    // 返回
    clickReturn(){
      this.clickMusic()
      clearInterval(this.timer)
      this.$router.back()
    },
    // 倒计时
    timeFn() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timer);
          this.$router.back()
        }
      }, 1000);
    },
  }
}
</script>

<style scoped>
.home{
  height: 100%;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.nav-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  /*position: relative;*/
  font-size: 30px;
}
.arrow{
  /*position: absolute;*/
  /*left: 20px;*/
  display: flex;
  align-items: center;
}
.arrow img{
  width: 12px;
  height: 20px;
  margin-right: 20px;
}

.content{
  width: 80%;
  /*background: red;*/
  margin: 30px auto;
}

.tips-items img{
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.submit-bar{
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submit-bar .row{
  display: flex;
  align-items: center;
}
.submit-bar .total {
  color: #fe5833;
}
.submit-bar .row p{
  margin-right: 20px;
  font-size: 28px;
}
.submit-bar .row img{
  width: 50px;
  height: 50px;
}

.btn{
  background: #ff9513;
  border-radius: 5px;
  margin: 0 20px;
  padding: 10px 20px;
  color: white;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.wrapper img {
  width: 120px;
  height: 120px;
}

/* 分类结构 */
.tree-select{
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
}

/* 商品分类左侧 */
.tree-select-left{
  width: 28%;
  height: 100%;
  background-color: #f7f8fa;
  overflow-y: scroll;
  padding-bottom: 50px;
}

.tree-select-left .item{
  /*padding:20px 0;*/
  display: flex;
  align-items: center;
}

.actice{
  background-color: #d3e3fd;
}
.tips-actice{
  background-color: #d3e3fd;
}
.text-active{
  color: #000000;
}

.tree-select-left .item .tips{
  width: 6px;
  height: 30px;
  border-radius: 10px;
}

.tree-select-left .item .text{
  /*margin-left: 20px;*/
  font-size: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* 商品右侧详情 */
.tree-select-right{
  width: 72%;
  height: 100%;
  overflow-y: scroll;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
.tree-select-right .item{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 250px;
  padding: 20px 15px 10px;
  box-sizing: border-box;
  background: white;
  box-shadow: 0 4px 4px #eeeeee;
  border-radius: 10px;
  position: relative;
  margin-right: 15px;
}

.tree-select-right .item .post-left-top{
  position: absolute;
  top: 10px;
  left: 10px;
  width: 55px;
  height: 55px;
  border-radius: 30px;
  background: #f7c777;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #000;
  z-index: 22;
}

.tree-select-right .item .post-right-top{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: solid 4px #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  z-index: 222;
}

.tree-select-right .item img{
  width: 150px;
  height: 120px;
  border-radius: 20px;
}

.tree-select-right .item .title{
  font-size: 16px;
  color: #333333;
  font-weight: 700;
}

.tree-select-right .item .price{
  font-size:18px;
  color: #fe5833;
  font-weight: 700;
  margin-top: -10px;
}

.tree-select-right .item .vip-price{
  background: #f7c777;
  /*padding: 2px 8px 6px;*/
  border-radius: 40px;
  color: white;
  font-size: 16px;
  margin-top: -10px;
}

/* 修改滚动条的样式 */
::-webkit-scrollbar {
  width: 10px; /* 设置滚动条的宽度 */
}

::-webkit-scrollbar-track {
  background-color: #eee; /* 设置滚动条的背景色 */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* 设置滚动条的前景色 */
}


/* 弹窗 */
.popup{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}
.popup p:nth-child(1){
  font-size: 38px;
  font-weight: 700;
  color: #333333;
}
.popup p:nth-child(2){
  font-size: 28px;
  color: #333333;
  margin-top: -10px;
}
.popup .qrcode{
  width: 260px;
  height: 260px;
  margin: 50px 0 30px;
}
.popup .btn{
  border: none;
  color: #ffffff;
  font-size: 28px;
  border-radius: 10px;
  width: 300px;
  padding: 10px 0;
  background: #f7c777;
}

</style>
